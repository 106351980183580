import React from "react"

// tut: https://www.gatsbyjs.com/blog/2019-01-31-using-react-context-api-with-gatsby/

const defaultState = {
  color: '#f7f7f7',
  setColor: () => {},
  filter: 'UX',
  setFilter: () => {}
}

const StateContext = React.createContext(defaultState)

class StateProvider extends React.Component {
  state = {
    color: '#f7f7f7',
    filter: 'UX'
  }

  setColor = (hex) => {
		this.setState({color: hex})
  }

  setFilter = (filter) => {
		this.setState({filter: filter})
  }

  render() {
    const { children } = this.props
    const { color, filter } = this.state

    // console.log('context rendered')
    return (
      <StateContext.Provider
        value={{
          color,
          setColor: this.setColor,
          filter,
          setFilter: this.setFilter
        }}
      >
        {children}
      </StateContext.Provider>
    )
  }
}

export default StateContext
export { StateProvider }