import React from "react"
import { Link } from "gatsby"
import './nav.scss'
import { motion } from "framer-motion"
import logo from "../../images/logo.svg"

const navVariants = {
	initial: (oldRoute) => ({
		opacity: 0,
		y: oldRoute ? 0 : 80,
		pointerEvents: 'none',
	}),
	exit: {
		opacity: 0,
		y: 0,
		pointerEvents: 'none',
		transition: {
			type: "tween",
			ease: "easeInOut",
			duration: .3
		}
	},
	enter: (oldRoute) => ({
		opacity: 1,
		y: 0,
		pointerEvents: 'auto',
		transition: {
			y: {
				type: "spring",
				damping: 45,
				mass: 2,
				stiffness: 200,
				delay: oldRoute ? .3 : 1.2
			},
			default: {
				type: "tween",
				ease: "easeInOut",
				duration: oldRoute ? .5 : .8,
				delay: oldRoute ? .3 : 1.3
			}
		}
	})
}



export default (props) => {
	let navAnimation = props.page.includes('/case-study/') ? "exit" : "enter"

	return (
		<motion.div
			className="nav" 
			initial="initial"
			animate={navAnimation}
			variants={navVariants}
			custom={props.oldRoute}
		>
			<Link to="/">
				<img className="nav__logo" src={logo} alt="logo"></img>
			</Link>
			<div className="nav__links">
				<Link
					to="/"
					className="nav__link --home"
					activeClassName="active"
				>
					Work
				</Link>
				<Link
					to="/info"
					className="nav__link --info"
					activeClassName="active"
					partiallyActive={true}
				>
					Info
				</Link>
			</div>
		</motion.div>
	)
}