import React, { memo, useEffect, useState } from "react"
import { motion, useAnimation, useMotionValue, useMotionTemplate } from "framer-motion"
import './cursor.scss'
import {durations} from './variants'

// Canvas experiment --> https://jsfiddle.net/anfv79wz/2/

const innerTransition = {
	type: 'spring',
	damping: 20,
	mass: .2,
	stiffness: 250
}

const outerTransition = (clicked) => ({
	type: 'spring',
	damping: 22,
	mass: clicked ? .03 : 1.8,
	stiffness: clicked ? 800 : 200,
	restDelta: 0.00001
})

const Cursor = (props) => {

	const expansionControls = useAnimation()
	const x = useMotionValue(0)
	const y = useMotionValue(0)
	const position = useMotionTemplate`translate3d(${x}px, ${y}px, 0)`
	
	// Set up
	const [hidden, setHidden] = useState(false)
	const [clicked, setClicked] = useState(false)
	const [linkHovered, setLinkHovered] = useState(false)

	// Add Listeners
	useEffect(() => {
		addGlobalListeners()
		return () => removeGlobalListeners()
	}, [])

	// Add link listeners on route change
	useEffect(() => {
		let links
		if (props.page.includes('/case-study/')) {
			// reset in case transition prevents mouseout
			setTimeout(() => {
				links = document.querySelectorAll("a, .button")
				addHoverListeners(links)
				setLinkHovered(false)
			}, (durations.caseToCase + .1) * 1000) // Using caseToCase rather than toCase because its longer duration
		} else {
			setTimeout(() => {
				links = document.querySelectorAll("a, .button")
				addHoverListeners(links)
				setLinkHovered(false)
			}, (durations.default + .1) * 1000) // .1 in case slow load
		}
		return () => removeHoverListeners(links)
	}, [props.page])

	// Update link listeners for project filter change
	useEffect(() => {
		const projects = document.querySelectorAll(".project")
		addHoverListeners(projects)
		return () => removeHoverListeners(projects)
	}, [props.filter])

	const addGlobalListeners = () => {
		document.addEventListener("mousemove", onMouseMove)
		document.addEventListener("mouseenter", onMouseEnter)
		document.addEventListener("mouseleave", onMouseLeave)
		document.addEventListener("mousedown", onMouseDown)
		document.addEventListener("mouseup", onMouseUp)
	}

	const removeGlobalListeners = () => {
		document.removeEventListener("mousemove", onMouseMove)
		document.removeEventListener("mouseenter", onMouseEnter)
		document.removeEventListener("mouseleave", onMouseLeave)
		document.removeEventListener("mousedown", onMouseDown)
		document.removeEventListener("mouseup", onMouseUp)
	}

	const addHoverListeners = (elements) => {
		for (let el of elements) {
				el.addEventListener("mouseenter", hoverStart)
				el.addEventListener("mouseleave", hoverEnd)
		}
	}

	const removeHoverListeners = (elements) => {
		for (let el of elements) {
				el.removeEventListener("mouseenter", hoverStart)
				el.removeEventListener("mouseleave", hoverEnd)
		}
	}

	const hoverStart = () => {
		setLinkHovered(true)
	}

	const hoverEnd = () => {
		setLinkHovered(false)
	}

	const onMouseMove = (e) => {
		x.set(e.clientX)
		y.set(e.clientY)
	}

	const onMouseLeave = () => {
		setHidden(true)
	}

	const onMouseEnter = () => {
		setHidden(false)
	}

	const onMouseDown = () => {
		setClicked(true)

		expansionControls.stop()

		expansionControls.set({
			scale: 0,
			opacity: .3,
			x: x.current,
			y: y.current
		})

		setTimeout(() => {
			expansionControls.start({
				scale: 1,
				opacity: 0,
				transition: {
					opacity: {
						type: 'spring',
						damping: 20,
						mass: .5,
						stiffness: 90
					},
					default: {
						type: 'spring',
						damping: 20,
						mass: .5,
						stiffness: 90
					}
				},
				transitionEnd: {
					scale: 0,
					opacity: .3
				}
			})
		}, 20)
	}

	const onMouseUp = () => {
		setClicked(false)
	}

	// FIXME: Tons of re-renders since it uses state for hover / click. Maybe able to optimize?
	// console.log('Cursor')

  return (
		<>
			<motion.div className="cursor-container" style={{transform: position}}>
				<motion.div
					className="cursor"
					animate={{
						opacity: hidden ? 0 : 1,
						scale: clicked && linkHovered ? 2 : clicked ? .67 : linkHovered ? 2.5 : 1
					}}
					transition={outerTransition(clicked)}
				>
					<motion.div
						className="cursor__border"
						animate={{
							scale: clicked && linkHovered ? 1.1 : clicked ? .92 : linkHovered ? 1.11 : 1
						}}
						transition={outerTransition(clicked)}
					/>
					<motion.div
						className="cursor__inner"
						animate={{
							scale: linkHovered ? .05 : 0,
							opacity: linkHovered ? .2 : 0,
						}}
						transition={innerTransition}
					/>
				</motion.div>
			</motion.div>
			<motion.div
				className="cursor__expansion"
				key={'cursor__expansion'}
				initial={false}
				animate={expansionControls}
			>
			</motion.div>
		</>
  )
}

export default memo(Cursor)