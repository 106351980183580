/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// This code needs to be duplicated inside of gatsby-ssr.js. Make sure to make any changes in BOTH places
import React from "react"
import PageWrapper from './src/components/global/page-wrapper'
import {durations} from './src/components/global/variants'
import { StateProvider } from "./src/components/global/StateContext"

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props}>{element}</PageWrapper>
)

export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
)

export const onInitialClientRender = () => {
  // Disable default. Was interfering w/ my custom restoration
  window.history.scrollRestoration = 'manual'
}

// Manage scrolling to top (or not) when doing page transitions
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps
}) => {
  // 
  let prevLocation = prevRouterProps ? prevRouterProps.location : null

  if (!prevLocation) {
    return false;
  }

  let delay
  if (location.pathname.includes('/case-study/')) {
    if (prevLocation.pathname.includes('/case-study/')) {
      delay = durations.caseToCase * 1000
    } else {
      delay = durations.toCase * 1000
    }
  } else {
    delay = durations.default * 1000
  }

  if ((prevLocation.pathname.includes('/case-study/') && location.pathname === '/') || location.action !== "PUSH") {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => {
      window.scrollTo(...(savedPosition || [0, 0]))
    }, delay) //+ 80 // adding some extra time (+80ms) in case saved scroll is beyond the bottom of the exiting page and site is a bit slow (it goes to top of page if that happens)
  } else {
    window.setTimeout(() => {
      window.scrollTo(0, 0)
    }, delay)
  }
  return false;
}