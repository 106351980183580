import { useState, useEffect } from 'react';


export const debounce = (fn, wait = 0) => {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.call(this, ...args)
    }, wait)
  }
}

export const throttle = function (fn, threshhold) {
  threshhold || (threshhold = 250)
  let last
  let timer = null

  return function () {
    const now = Date.now()
    const args = arguments
    if (last && now < last + threshhold) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, threshhold + last - now)
    }
    else {
      last = now
      fn.apply(this, args)
    }
  }
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    // vh: undefined
  })

  useEffect(() => {

    // const stick = document.getElementById('v-measure')

    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        // vh: stick.offsetHeight
      })
    }, 400)
    
    // Add event listener
    window.addEventListener("resize", handleResize, {passive: true})
    
    // Set right away, directly to avoid debounce, so state gets updated with initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      // vh: stick.offsetHeight
    })
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize, {passive: true})
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

export const isMobile = () => {
  if (typeof navigator !== 'undefined') {
    const ua = navigator.userAgent
    return /Android|Mobi/i.test(ua)
  } else {
    return false
  }
}