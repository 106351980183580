// Page Animations

// Durations are imported into gatsby-browser to sync scrolling to top with the exit animations
// Used for exit duration and enter delay
export const durations = {
  toCase: .6,
  caseToCase: .6,
  default: .3,
  first: 1
}

const instantExit = {
  opacity: 0,
  transition: {
    duration: 0
  }
}

// Defaults

const defaultInitial = {
  opacity: 0,
  y: 150,
  position: 'absolute'
}

const defaultExit = {
  opacity: 0,
  y: -30,
  transition: {
    opacity: { 
      type: "tween",
      ease: "easeIn",
      duration: durations.default - .1 // Some padding for when it scrolls to top
    },
    y: {
      type: "tween",
      ease: "easeIn",
      duration: durations.default
    },
    default: { duration: durations.default },
  }
}

const defaultEnter = {
  opacity: 1,
  y: 0,
  transition: {
    y: {
      type: "spring",
      damping: 45,
      mass: 2,
      stiffness: 200,
      delay: durations.default
    },
    default: {
      type: "tween",
      ease: "easeOut",
      duration: .7,
      delay: durations.default + .1
    },
  },
  transitionEnd: {
    position: 'relative'
  }
}


// First Load
const firstInitial = {
  opacity: 0,
  y: 200
}

const firstEnter = {
  opacity: 1,
  y: 0,
  transition: {
    y: {
      type: "tween",
      ease: [.15,.15,0,1],
      duration: 1,
      delay: durations.first
    },
    default: {
      type: "tween",
      ease: "easeOut",
      duration: .9,
      delay: durations.first
    },
  }
}



// Study

const studyInitial = {
  opacity: 0,
  position: 'absolute'
}

// To case study
const homeToStudy = {
  opacity: 0,
  transition: {
    type: "tween",
    ease: "easeInOut",
    duration: 0,
    delay: durations.toCase + .1 // wait .1s after scroll to hide
  }
}

const studyToStudyExit = {
  opacity: 0,
  transition: {
    type: "tween",
    ease: "easeIn",
    duration: .1, // page removed from dom after this
    delay: durations.caseToCase + .1 // wait .1s after scroll to hide
  }
}

const studyEnter = {
  opacity: 1,
  position: 'relative',
  transition: {
    position: {
      delay: durations.toCase + .2 // manual delay instead of transitionend because exiting page overlaps by .1
    },
    default: {
      type: 'tween',
      duration: 0,
      delay: durations.toCase
    }
  }
}


// Exports


// Initial and Animate get custom prop from the incoming / current page.
// Exit gets custom prop from AnimatePresence

export const defaultVariants = {
  initial: (oldRoute) => {
    return oldRoute ? defaultInitial : firstInitial
  },
  animate: (oldRoute) => {
    return oldRoute ? defaultEnter : firstEnter
  },
  exit: defaultExit
}

export const homeVariants = {
  initial: (oldRoute) => {
    return oldRoute ? defaultInitial : firstInitial
  },
  animate: (oldRoute) => {
    return oldRoute ? defaultEnter : firstEnter
  },
  exit: (newRoute) => {
    if (newRoute && newRoute.includes('/case-study/')) {
      return homeToStudy
    }
    else {
      return defaultExit
    }
  }
}

export const infoVariants = {
  initial: (oldRoute) => {
    return oldRoute ? defaultInitial : firstInitial
  },
  animate: (oldRoute) => {
    return oldRoute ? defaultEnter : firstEnter
  },
  exit: defaultExit
}

export const studyVariants = {
  initial: (oldRoute) => {
    if (!oldRoute) {
      return firstInitial
    } else {
      return studyInitial
    }
  },
  animate: (oldRoute) => {
    if (!oldRoute) {
      return firstEnter
    } else {
      return studyEnter
    }
  },
  exit: (newRoute) => {
    if (newRoute && newRoute.includes('/case-study/')) {
      return studyToStudyExit
    } else {
      return defaultExit
      // return instantExit
    }
  }
}