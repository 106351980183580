import React from "react"
import {motion} from "framer-motion"
import './loader.scss'

export default (props) => {

	const loaderVariants = {
		initial: {
			opacity: 1,
			scaleX: 1,
			y: 0
		},
		animate: (timing) => {
			return {
				opacity: 0,
				y: -50,
				scaleX: .5,
				transition: {
					opacity: {
						type: "tween",
						ease: [.5,0,.5,1],
						duration: .3,
						delay: timing && timing.duration ? timing.duration : .2
					},
					default: {
						type: "tween",
						ease: [0,0,1,0],
						duration: .5,
						delay: timing && timing.duration ? timing.duration -.2 : .2
					}
				}
			}
		}
	}

	const loaderLineVariants = {
		initial: {
			scaleX: 0
		},
		animate: (timing) => {
			return {
				scaleX: 1,
				transition: {
					type: "tween",
					ease: [.47,.09,0,1],
					duration: timing && timing.duration ? timing.duration : .3,
				}
			}
		}
	}

	return (
		<motion.div
		className="loader"
		initial="initial"
		animate={props.loaderControls}
		variants={loaderVariants}
		custom={props.timing}
		>
		<motion.div
			className="loader__line --filled"
			initial="initial"
			animate={props.loaderLineControls}
			variants={loaderLineVariants}
			custom={props.timing}
		/>
		<div className="loader__line --empty"></div>
		</motion.div>
	)
}