import React, {useEffect, useState} from "react"
import Loader from '../global/loader'
import Background from '../global/background'
import Nav from "../global/nav"
import {AnimatePresence, useAnimation} from 'framer-motion'
import StateContext from './StateContext'
import usePrevious from '../helpers/usePrevious'
import Cursor from "./cursor"
import {debounce} from '../helpers/util'

import "../../styles/base.scss"
import "../../styles/global.scss"

export default (props) => {

  const loaderControls = useAnimation()
  const loaderLineControls = useAnimation()
  const oldRoute = usePrevious(props.location.pathname)
  const [hoverable, setHoverable] = useState(typeof window !== 'undefined' && window.matchMedia('(hover:hover)').matches)
  
  loaderLineControls.start("animate")
  loaderControls.start("animate")

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
        oldRoute: oldRoute,
    }),
  )

  // Re Render after a resize to make sure cursor displays correctly if user checks mobile view in inspect
  const handleResize = debounce(() => {
    setHoverable(window.matchMedia('(hover:hover)').matches)
  }, 1000)

  useEffect(()=>{
    window.addEventListener('resize', handleResize, {passive: true})
    return () => window.removeEventListener('resize', handleResize, {passive: true})
  },[])

  // console.log(oldRoute)
  
  return (
    <StateContext.Consumer>
      {context => (
        <>
          {/* <div id="v-measure"></div> */}
          <Loader timing={{duration: .5}} loaderControls={loaderControls} loaderLineControls={loaderLineControls}/>
          <div className="noise">
            <div className="noise__dark"></div>
            <div className="noise__light"></div>
          </div>
          <Nav oldRoute={oldRoute} page={props.location.pathname}/>
          <Background context={context} page={props.location.pathname}/>
          {hoverable && <Cursor page={props.location.pathname} filter={context.filter}/>}
          <AnimatePresence
            initial={true}
            // custom sends this value to the exiting page
            // it is accessible through the page's dynamic variants to decide which transition to use
            custom={props.location.pathname}
          >
            {childrenWithProps}
          </AnimatePresence>
        </>
      )}
    </StateContext.Consumer>
  )
}

