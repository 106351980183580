// 
// 
// NOTE: Switched to non-context implementation. Sets colors only on page change. Avoids a re-render
// If you need to change colors outside of route changes, switch back to the commented out version


import React, {useEffect, useState, useRef, memo} from "react"
import { motion } from "framer-motion"

const Background = (props) => {
  
  const [topActive, setTopActive] = useState(false)
  const color = useRef('#f7f7f7')
  const top = useRef('#f7f7f7')
  const bottom = useRef('#f7f7f7')

  useEffect(() => {
    let newColor
    if (props.page === '/') {
      newColor = "#f7f7f7"
    } else if (props.page === '/info' || props.page === '/info/') {
      newColor = "#BAC2BC"
    }

    if (color.current !== newColor) {
      color.current = newColor

      if (topActive) {
        bottom.current = newColor
      } else {
        top.current = newColor
      }
      setTopActive(!topActive)
    }
  }, [props.page])

  // console.log('Background')

  return (
    <>
    <motion.div
      className="background --top"
      initial={false}
      style={{backgroundColor: top.current}}
      animate={{
        opacity: topActive ? 1 : 0
      }}
      transition={{
        type: 'tween',
        ease: [.4,0,0,1],
        duration: .4,
      }}
    >
    </motion.div>
    <div className="background --bottom" style={{backgroundColor: bottom.current}}></div>
    </>
  )
}

export default memo(Background)







// import React, {useEffect, useState, useRef, memo} from "react"
// import { motion } from "framer-motion"

// const Background = (props) => {
  
//   const [topActive, setTopActive] = useState(false)
//   const color = useRef(props.context.color.color)
//   const duration = useRef(.4)
//   const delay = useRef(0)
//   const top = useRef('#f7f7f7')
//   const bottom = useRef('#f7f7f7')

//   useEffect(() => {
//     // If color changed
//     if (color.current !== props.context.color.color) {
//       // Update refs
//       color.current = props.context.color.color
//       duration.current = props.context.color.duration ? props.context.color.duration : .4
//       delay.current = props.context.color.delay ? props.context.color.delay : 0

//       if (topActive) {
//         bottom.current = props.context.color.color
//       } else {
//         top.current = props.context.color.color
//       }
//       setTopActive(!topActive)
//     }
//   },[props.context.color])

//   // Set page colors. Case study colors are set during the animation
//   useEffect(() => {
//     if (props.page === '/') {
//       props.context.setColor({color: "#f7f7f7"})
//     } else if (props.page === '/info' || props.page === '/info/') {
//       props.context.setColor({color: '#BAC2BC'})
//     }
//   }, [props.page])

//   console.log('Background')

//   return (
//     <>
//     <motion.div
//       className="background --top"
//       initial={false}
//       style={{backgroundColor: top.current}}
//       animate={{
//         opacity: topActive ? 1 : 0
//       }}
//       transition={{
//         type: 'tween',
//         ease: [.4,0,0,1],
//         duration: duration.current,
//         delay: delay.current
//       }}
//     >
//     </motion.div>
//     <div className="background --bottom" style={{backgroundColor: bottom.current}}></div>
//     </>
//   )
// }

// export default memo(Background)